@import url(./Container.css);

:root {
    --primary: #101941;
    --light-blue: rgba(32, 53, 148, 0.2);
    --white: #ffffff;
    --light: #e5e5e5;
    --accent: #ff914d;
    --button-background: linear-gradient(180deg, #fc441e 0%, #fe5567 100%);
    --green: #12d377;
    --red: #f94054;
}

table {
    border-collapse: collapse;
    width: 100%;
    color: #101941;
}

tr {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    margin-top: 5px;
}

tr:hover {
    box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}

.table_icon_wrapper {
    height: 32px;
    width: 32px;
}

.table_icon {
    height: 20px;
    width: 20px;
    transition: all 0.2s ease;
}

.table_icon:hover {
    height: 27px;
    width: 27px;
}

.table_icon_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.table_edit_icon {
    height: 20px;
    width: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.table_edit_icon:hover {
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.table_delete_icon {
    height: 16px;
    width: 16px;
    transition: all 0.2s ease;
}

.table_delete_icon:hover {
    height: 20px;
    width: 20px;
}

.table_setting_icon {
    height: 18px;
    width: 18px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.table_setting_icon:hover {
    height: 22px;
    width: 22px;
    cursor: pointer;
}

.filter_icon_wrapper {
    height: 44x;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.filter_icon {
    height: 36px;
    width: 36px;
    transition: all 0.2s ease;
}

.filter_icon:hover {
    height: 44px;
    width: 44px;
}

.credit_icon_wrapper {
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.credit_icon {
    height: 28px;
    width: 28px;
    transition: all 0.2s ease;
    cursor: pointer;
}

.credit_icon:hover {
    height: 34px;
    width: 34px;
}

.menu {
    background-color: #ffffff;
    margin: 0;
    padding: 0;
}

.menu tr {
    height: auto;
    padding: 0px;
}

.menu tr:hover {
    box-shadow: none;
}

.container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
}

.view__wrapper {
    display: flex;
    flex: 1;
}

.full-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    background-color: var(--white);
}

.logo__image {
    height: 35px;
}

.sub-title {
    font-size: 12px;
    color: var(--primary);
    text-decoration: none;
}

.connector-image {
    width: 20px;
    height: 20px;
    object-fit: contain;
    filter: brightness(0) invert(1);
}

.connector-image-profile {
    object-fit: contain;
    filter: brightness(0) invert(1);
}

.sub-title__accent {
    font-size: 12px;
    color: var(--accent);
    text-decoration: none;
}

.sub-title-bold {
    font-size: 12px;
    color: var(--primary);
    font-weight: 500;
    text-decoration: none;
}

.sub-title-bolder {
    font-size: 12px;
    color: var(--primary);
    font-weight: bold;
    text-decoration: none;
}

.small-sub-title__accent {
    font-size: 10px;
    color: var(--accent);
    text-decoration: none;
}

.small-sub-title {
    font-size: 10px;
    color: var(--primary);
    text-decoration: none;
}

.title-bold {
    font-size: 14px;
    color: var(--primary);
    font-weight: bold;
}

.title {
    font-size: 14px;
    color: var(--primary);
    font-weight: 500;
    line-height: normal;
}

.large-title {
    font-size: 30px;
    color: var(--primary);
    font-weight: bold;
}

.center {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    flex-direction: column;
}

.center-row {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
}

.rfid {
    font-size: 20px !important;
    font-weight: 500 !important;
    padding: 10px 20px !important;
}

.rfid_delete {
    font-weight: 500;
    text-decoration-line: underline;
    padding: 10px 20px !important;
}

.rfid_delete:hover {
    font-weight: 600 !important;
}

.rfid_add {
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 10px 20px !important;
    text-decoration-line: underline !important;
    cursor: pointer;
}

.rfid_add:hover {
    font-weight: 600 !important;
}

.rfid_data {
    padding: 10px 20px !important;
}

.rfid_menu:hover {
    box-shadow: none !important;
}

h1 {
    color: var(--primary);
    font-weight: bold;
    font-size: 25px;
}

h3 {
    color: var(--primary);
    font-weight: bold;
    font-size: 20px;
}

h2,
h4,
h5,
h6 {
    color: var(--primary);
    font-weight: bold;
}

input[type="button"] {
    background: var(--button-background) !important;
    border-radius: 10px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    outline: 0;
    padding: 10px;
    width: 150px;
    border: none;
    font-size: 14px;
    margin: 5px;
    font-weight: 500;
    cursor: pointer;
}

.login_button {
    background: linear-gradient(rgb(252, 68, 30) 0%, rgb(254, 85, 103) 100%) !important;
    color: rgb(255, 255, 255) !important;
    width: 100%;
    padding: 8px 10px !important;
    border-radius: 8px !important;
    font-size: 500 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: none !important;
}

.spacer {
    display: flex;
    align-items: center;
    margin: 5px;
    flex-direction: row;
}

.viewH {
    display: flex;
    flex-direction: row;
}

.viewV {
    display: flex;
    flex-direction: column;
}

.pt_0 {
    padding-top: 0;
}

.padding-5 {
    padding: 5px !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-15 {
    padding: 15px !important;
}

.padding-20 {
    padding: 20px !important;
}

.padding-r-10 {
    padding-right: 10px !important;
}

.padding-r-25 {
    padding-right: 25px !important;
}

.padding-r-70 {
    padding-right: 70px !important;
}

.padding-t-10 {
    padding-top: 10px !important;
}

.padding-t-5 {
    padding-top: 5px !important;
}

.padding-b-10 {
    padding-bottom: 10px !important;
}

.padding-b-5 {
    padding-bottom: 5px !important;
}

.padding-l-10 {
    padding-left: 10px !important;
}

.p_0 {
    padding: 0 !important;
}

.pr_5 {
    padding-right: 5px;
}

.py_6 {
    padding: 6px 0;
}

.py_10 {
    padding: 10px 0px;
}

.py_20 {
    padding: 20px 0px;
}

.pb_20 {
    padding-bottom: 20px;
}

.margin-r-5 {
    margin-right: 5px !important;
}

.margin-r-10 {
    margin-right: 10px !important;
}

.margin-v-10 {
    margin: 10px 0px !important;
}

.margin-t-10 {
    margin-top: 10px !important;
}

.margin-t-8 {
    margin-top: 8px !important;
}

.margin-t-5 {
    margin-top: 5px !important;
}

.margin-b-5 {
    margin-bottom: 5px !important;
}

.margin-b-10 {
    margin-bottom: 10px !important;
}

.margin-b-12 {
    margin-bottom: 12px;
}

.margin-b-20 {
    margin-bottom: 20px !important;
}

.margin-l-10 {
    margin-left: 10px !important;
}

.m_l_0 {
    margin-left: 0px !important;
}

.ml_5 {
    margin-left: 5px;
}

.ml_10 {
    margin-left: 10px;
}

.ml_24 {
    margin-left: 24px;
}

.mt_15 {
    margin-top: 15px !important;
}

.mt_24 {
    margin-top: 24px;
}

.mt_30 {
    margin-top: 30px;
}

.m_r_10 {
    margin-right: 8px !important;
}

.m_r_8 {
    margin-right: 10px !important;
}

.mr_20 {
    margin-right: 20px;
}

.mx_10 {
    margin: 0 10px;
}

.margin_0 {
    margin: 0;
}

.mb_8 {
    margin-bottom: 8px;
}

.mb_12 {
    margin-bottom: 12px;
}

.mb_0 {
    margin-bottom: 0 !important;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.mt-2p {
    margin-top: 2%;
}

.mt_2 {
    margin-top: 2px;
}

.-mt-2 {
    margin-top: -2px !important;
}

.-mt-5 {
    margin-top: -5px !important;
}

.-mt_10 {
    margin-top: -10px;
}

.-mt_15 {
    margin-top: -15px !important;
}

.-mt_20 {
    margin-top: -20px;
}

.-ml_10 {
    margin-left: -10px !important;
}

.mt_8 {
    margin-top: 8px;
}

.mt_20 {
    margin-top: 20px;
}

.mt_28 {
    margin-top: 28px !important;
}

.mt_60 {
    margin-top: 60px;
}

.my_5 {
    margin: 5px 0;
}

.my_10 {
    margin: 10px 0;
}

.my_12_5 {
    margin: 12.5px 0;
}

.h_220 {
    height: 220px;
}
.h_36 {
    height: 36px !important;
}

.h_full {
    height: 100%;
}

.no-outline {
    outline: none;
    border: none;
    color: var(--primary);
    background-color: transparent;
}

.span-btn {
    cursor: pointer;
}

.h-dash {
    border: 1px var(--white) dashed;
}

.error-message {
    color: red;
    text-align: center;
    font-weight: bold;
}

.error-message-light {
    color: red;
    text-align: center;
}

.progress {
    background-color: #f5f5f5;
    border-radius: 9px;
    box-shadow: none;
}

.progress.vertical {
    position: relative;
    width: 40px;
    height: 90%;
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
}

.progress {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress.vertical > .progress-bar {
    width: 100% !important;
    position: absolute;
    bottom: 0;
}

.progress-bar {
    background-color: #123abc;
    box-shadow: none;
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}

.edit-button {
    align-items: center;
    /* background: linear-gradient(180deg, #fc441e, #fe5567); */
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 26px;
}

.setting-button {
    align-items: center;
    /* background: linear-gradient(180deg, #fc441e, #fe5567); */
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 26px;
}

/* .pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
} */

.dashboard-info-block {
    display: flex;
    flex: 1 1;
    border: 1px solid rgba(32, 53, 148, 0.1);
    box-sizing: border-box !important;
    border-radius: 20px;
    padding: 4px;
    flex-direction: column;

    margin: 12px !important;
    position: relative;
    overflow: hidden;
}

.dashboard__analytics {
    display: flex;
    border: 1px solid rgba(32, 53, 148, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;
    min-width: 250px;
    margin: 5px;
    position: relative;
    overflow: hidden;
    height: max-content;
}

.dashboard__infocontainer {
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
}

.overview_data {
    color: #12d377;
    margin: 5px 0px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.overview_label {
    color: #101941 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.overview_graph_header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0% -18% 0% -13%;
}

.table_heading {
    display: flex;
    justify-content: space-between;
}

.main_table_header {
    height: 36px;
    margin-bottom: 10px;
}

.button_radius {
    border-radius: 10px;
}

.info-block-wrapper {
    display: flex;
    flex: 1;
    border: 1px solid rgba(32, 53, 148, 0.1);
    box-sizing: border-box !important;
    border-radius: 20px;
    padding: 4px;
    flex-direction: column;
    margin: 5px !important;
    position: relative;
    overflow: hidden;
    background: #f5f6f7;
}

.info__icon {
    width: 34px;
    height: 34px;
}

.wallet_data_block_container {
    padding: 20px 0;
    align-items: flex-start;
    gap: 10px;
}

.wallet_data_block {
    justify-content: space-between;
    display: flex;
    width: 24vw;
    background: #fff;
}

.wallet_header_block {
    justify-content: space-between;
    display: flex;
    /* width: 20vw; */
    padding: 12px;
    background: #f5f6f7;
    /* flex-direction: column; */
    border-radius: 10px;
}
.flex {
    display: flex;
}

.flex_wrap {
    flex-wrap: wrap;
}

.flex_align_center {
    display: flex;
    align-items: center;
}

.flex_start {
    display: flex;
    align-items: center;
    justify-content: start;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.justify_end {
    justify-content: end;
}

.ocpp_span {
    width: 132px;
}

.ocpp_left_span {
    width: 95px;
}

.ocpp_unlock {
    display: flex;
    align-items: center;
    width: 90%;
}

.ocpp_header {
    display: flex;
    align-items: center;
    width: 48%;
}

.flex_even {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.flex_end {
    display: flex;
    justify-content: flex-end;

    align-items: center;
}

.flex_center {
    display: flex;
    justify-content: center;
}

.wallet_data_block_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: break-all;
    margin-right: 20px;
}

.rfidError {
    color: #f94054;
    font-size: 10px;
    margin-top: 1%;
}

.wallet_wrapper {
    display: flex;
    border: 1px solid rgba(32, 53, 148, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 4px;
    cursor: pointer;
}

.wallet_icon_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    margin-left: 5px;
    cursor: pointer;
}

.submit_wrapper {
    padding: 14px 36px !important;
    border-radius: 6px;
}

.regular_font {
    font-weight: 400 !important;
    color: #101941 !important;
    font-size: 15px;
}

.regular_font_grey {
    font-weight: 400 !important;
    color: #70758d !important;
    font-size: 15px;
}

.small_font {
    font-weight: 400 !important;
    color: #101941 !important;
    font-size: 12px !important;
}

.small_font_grey {
    font-weight: 400 !important;
    color: #70758d !important;
    font-size: 12px !important;
}

.charger_profile_font {
    font-weight: 400 !important;
    color: #101941 !important;
    font-size: 12px;
    margin-top: 2%;
}

.company_profile_grid {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 10px;
}

.charger_connected {
    color: #12d377;
    font-size: 15px;
    font-weight: 500;
}

.modal_heading {
    font-weight: 500;
    font-size: 20px;
}

.regular_modal_font {
    font-weight: 500 !important;
    color: #101941 !important;
    font-size: 15px;
}

.filter_data_wrapper {
    display: flex;
    align-items: center;
}

.form_main_heading {
    margin-bottom: 20px;
}

.form_main_sub_heading {
    margin-bottom: 20px;
    font-weight: 500;
}

.wallet_source_font {
    font-size: 15px;
    font-weight: 500;
}

.overview_sub_heading {
    font-weight: 500;
    font-size: 20px;
}

.company-profile-box {
    box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.06), 3px 3px 10px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 20px;
    overflow: auto;
    height: 300px;
}

.details_margin {
    margin-bottom: 20px;
}

.total_user_wrapper {
    background-color: #e8e8ec;
    padding: 3px 10px;
    margin-right: 9px;
    border-radius: 10px;
}

.font20 {
    font-size: 20px;
}

.fs_10 {
    font-size: 10px;
}

.fs_12 {
    font-size: 12px !important;
}

.fs_14 {
    font-size: 14px;
}

.fs_16 {
    font-size: 16px !important;
}

.horizontal_progress {
    height: 28px;
    width: 100px;
    background-color: grey;
    border-radius: 5px;
    margin-right: 3px;
}

.horizontal_progressbar {
    height: 100%;
    border-radius: 5px;
    text-align: right;
}

.horizontal_progress_text {
    padding: 6px;
    color: #f5f6f7;
    font-weight: 500;
    display: flex;
    font-size: 15px;
}

.title-license {
    font-size: 14px;
    color: var(--primary);
    font-weight: 400;
}

.charger__name {
    cursor: pointer;
    color: #0430c0;
    text-decoration: underline;
    max-width: 250px;
    min-width: 150px;
    word-break: break-word;
    word-wrap: break-word;
}

.charger__name:hover {
    font-weight: 500 !important;
}

.charger__name span {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.status_label {
    width: 32% !important;
}

.download_button {
    border-radius: 5px;
    background: #101941;
    color: #fff;
    padding: 7.3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0px 2px;
}

.add_roles {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
}

.add_roles:hover {
    font-weight: bold;
}

.station_access {
    background-color: #f5f6f7;
    padding: 14px 24px;
    display: flex;
    margin: 10px 0px;
    color: #404767;
    font-weight: 14px;
    font-size: 14px;
}

.access_modal_padding {
    padding: 0px 24px;
}

.access_modal_heading {
    padding: 0px 24px;
    color: #f94054;
}

.align_center {
    display: flex;
    align-items: center;
}

.align_start {
    display: flex;
    align-items: flex-start;
}

.clickme {
    text-decoration: underline;
    color: #0430c0;
    cursor: pointer;
    overflow-wrap: break-word;
}

.pointer {
    cursor: pointer;
}

.secondary_heading {
    font-weight: 500;
    font-size: 20px;
    color: #fc441e;
}

.company_name_search_icon_container {
    display: flex;
    align-items: center;
    gap: 0 8px;
    height: 40px;
}

.company_name_search {
    margin: 8px 0 0 !important;
}

.five_hundred_page_container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.five_hundred_page_img_container {
    height: 50%;
    width: 70%;
}

.five_hundred_page_title {
    font-weight: 500;
    font-size: 35px;
    line-height: 44px;
    text-align: center;
    color: #404767;
    margin: 20px 0 40px;
}

.five_hundred_page_button {
    padding: 12px 120px !important;
    font-weight: 500 !important;
    font-size: 26px !important;
    background: #fc441e;
    border-radius: 10px !important;
}

.text_center {
    text-align: center;
}

.text_left {
    text-align: left;
}

@media only screen and (max-width: 1280px) {
    .five_hundred_page_img_container {
        height: auto;
        width: 70%;
    }
}

@media only screen and (max-width: 1024px) {
    .five_hundred_page_title {
        font-size: 30px;
        line-height: 36px;
    }

    .five_hundred_page_button {
        font-size: 25px !important;
        line-height: 20px;
    }
}

.form .MuiAutocomplete-input,
.MuiAutocomplete-inputFocused {
    padding: 5px !important;
}

.graph_heading {
    margin: 20px 15px;
}

.roles .MuiDialog-paperWidthSm {
    max-width: 770px !important;
}

.file_input_button {
    border: 1px solid #fc441e;
    border-radius: 5px;
    width: 100%;
    padding: 12px 0px;
    text-align: center;
    font-size: 14px;
    color: #fc441e;
    cursor: pointer;
}

.file_input_button:hover {
    opacity: 0.8;
}
.file_input {
    display: none;
}

.apexcharts-tooltip-text-y-value {
    color: #8884d8;
}

.apexcharts-datalabel-label {
    font-weight: 400;
    font-size: 10px;
}

.text-capitalize {
    text-transform: capitalize;
}

.Mui-disabled {
    -webkit-text-fill-color: inherit;
}

.connector_modal .refresh_white_icon {
    height: 28px;
    margin: auto 15px;
    cursor: pointer;
}

.MuiTooltip-tooltip {
    font-size: 12px !important;
    color: #101941 !important;
    background-color: white !important;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12) !important;
    font-weight: 400 !important;
    text-transform: capitalize;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.secondary_header_buttons img {
    margin-right: 8px;
    cursor: pointer;
}

.secondary_header_buttons div,
.secondary_header_buttons img {
    height: 100%;
}

.filter_heading_left {
    display: flex;
    gap: 5px;
    align-items: center;
}

.error {
    color: #f94054;
    font-size: 10px;
    font-weight: 500;
    padding: 5px;
}

.cursor_default {
    cursor: default !important;
}

.cursor_not_allowed {
    cursor: not-allowed !important;
}

.autocomplete .MuiInputBase-root,
.select_input {
    background-color: white;
}

.autocomplete .MuiOutlinedInput-notchedOutline,
.select_input .MuiOutlinedInput-notchedOutline {
    border-color: #e8e8ec;
}

.select_input .MuiSelect-select {
    text-align: start;
    font-size: 12px;
    color: var(--primary);
    font-weight: 500;
    text-decoration: none;
}

.col_width {
    max-width: 150px !important;
    min-width: 150px !important;
    word-break: break-word;
    word-wrap: break-word;
}

.url {
    color: #0430c0;
}

.copy {
    height: 16px;
    margin-bottom: -4px;
    margin-left: 2px;
    cursor: pointer;
}

.bold_500 {
    font-weight: 500;
}

.text_ellipsis {
    display: inline-block;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chip {
    color: #fc5735;
    margin-left: 5px;
    margin-bottom: 1px;
    font-weight: 500;
    height: 14px;
    min-width: fit-content;
    background-color: #fec7bc;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.input_label {
    margin-top: -4px;
    color: #b7bac6;
    font-size: 12.5px;
    z-index: 1;
}

.select_input_label {
    padding-top: 27px;
    color: #a0a2aa;
    font-size: 12.5px;
    z-index: 1;
}

.font_12 {
    font-size: 12px !important;
}

.font_14 {
    font-size: 14px !important;
}

.px_20 {
    padding: 20px 20px 0 35px !important;
}

.mt_0 {
    margin-top: 0 !important;
}

.flex_col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cursor_pointer {
    cursor: pointer;
}

.width_fit_content {
    min-width: fit-content;
}

.height_fit_content {
    height: fit-content;
}

.table_header {
    vertical-align: top;
}

.absolute_close_icon {
    position: absolute;
    top: 0px;
    right: 0px;
}

.gap_2 {
    gap: 2px;
}

.gap_4 {
    gap: 4px;
}

.gap_6 {
    gap: 6px;
}

.gap_8 {
    gap: 8px;
}

.gap_10 {
    gap: 10px !important;
}

.gap_15 {
    gap: 15px;
}

.gap_30 {
    gap: 30px;
}

.gap_50 {
    gap: 50px;
}
.image_container img {
    cursor: pointer;
}

.helper_text_error {
    color: #d32f2f;
    font-size: 9px;
    margin-top: 3px;
}

.MuiFormHelperText-root {
    margin: 0;
    margin-top: 3px;
}

.map_container {
    height: 76vh;
    width: 100%;
    border-radius: 10;
    border-width: 1;
    overflow: hidden;
    margin-top: 10;
    position: relative;
}
.map_search {
    position: absolute !important;
    top: 10px !important;
    left: 10px !important;
    padding-left: 10px;
    background-color: white !important;
}

.h_280 {
    height: 280px;
}

.max_w_70_p {
    max-width: 70%;
}

.border_style_dashed {
    border-style: dashed;
}

.w_48 {
    width: 48%;
}

.w_80 {
    width: 80px;
}

.w_100 {
    width: 100px;
}

.w_140 {
    width: 140px;
}

.w_200 {
    width: 200px;
}

.w_255 {
    width: 255px !important;
}

.w_25_p {
    width: 25%;
}

.min_w_150 {
    min-width: 150px;
}

.width_full {
    width: 100%;
}

.h_33 {
    height: 33px;
}

.min_h_700 {
    min-height: 700px;
}
.overflow_hidden {
    overflow: hidden;
}

.quick_filter_wrapper {
    display: flex;
    justify-content: space-between;
}

.radius_10 {
    border-radius: 10px;
}

.float_right {
    float: right;
}

.opacity_0 {
    opacity: 0;
}

.opacity_point_four {
    opacity: 0.4;
}

.opacity_1 {
    opacity: 1;
}

.border-radius-10 {
    border-radius: 10px;
}

.color_primary {
    color: var(--primary);
}

.bg_white {
    background-color: var(--white);
}

.graph_range_input_container {
    display: flex;
    align-items: end;
    padding: 10px 20px;
}

.graph_range_duration_container {
    width: 100px;
    padding-left: 10;
}

.column_input_container {
    width: 135px;
    height: 36px !important;
    margin-right: 8px;
}

.yellow {
    color: #ecb527;
}

.red {
    color: #f94054;
}

.green {
    color: #12d377 !important;
}

.orange {
    color: #fc441e;
}

.blue {
    color: #0430c0;
}

.theme_blue {
    color: #101941 !important;
}

.placeholder_gray {
    color: #70758d;
}

.flex_align_end {
    display: flex;
    align-items: end;
}

.z_999 {
    z-index: 999;
}

.text_underline {
    text-decoration-line: underline;
}

.overflowX_auto {
    overflow-x: auto;
}

.flex_1 {
    flex: 1 !important;
}

.visibility_none {
    visibility: hidden;
}

.placeholder_color {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}

.text_uppercase {
    text-transform: uppercase;
}

.MuiDialogContent-root::-webkit-scrollbar {
    display: none;
}
.min_w_40 {
    min-width: 40px;
}

.-mb-5 {
    margin-bottom: -5px;
}

.MuiOutlinedInput-input,
.MuiAutocomplete-option,
.MuiMenuItem-root {
    color: #101941;
}

.MuiDialogContent-root {
    padding: 20px;
}

.MuiDialogTitle-root {
    padding: 16px 20px;
}

.MuiButton-root {
    text-transform: capitalize;
    border-radius: 6px;
}

.border_select .MuiInputBase-root {
    background-color: white;
    height: auto;
    width: 100%;
}
.border_select .MuiInputBase-input {
    padding: 10px 32px 10px 12px !important;
    border-color: #b7bac6;
}

.border_select .MuiOutlinedInput-notchedOutline {
    border-color: #b7bac6;
}

.w_full {
    width: 100%;
}

.flex_grow {
    flex-grow: 1;
}

.apexcharts-legend-text {
    text-transform: capitalize;
}

.country_img {
    height: 10px;
    margin-right: 4px;
    margin-top: -1px;
}

.country_select {
    padding-left: 12px;
}

.country_select img,
.country_select_value img {
    height: 16px;
    margin-right: 6px;
}

.country_select div,
.country_select_value div {
    padding-top: 3px;
}

.phone_number .MuiOutlinedInput-root {
    padding-left: 12px;
    line-height: normal;
}

.phone_number .MuiInputAdornment-root {
    margin-right: 5px;
}

.phone_number input {
    padding-left: 0;
}

.phone_number .MuiTypography-root {
    line-height: normal;
}

.sidebar_wallet_form .MuiOutlinedInput-notchedOutline {
    border-color: #e8e8ec;
}

::-ms-reveal {
    display: none;
}

.position_relative {
    position: relative;
}

.position_absolute {
    position: absolute;
}

.position_abs_center {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}

.apex_graph_title {
    font-size: 16px;
    font-weight: 500;
    color: #101941;
}

.vertical_align_bottom {
    vertical-align: bottom;
}

.connector_row .MuiAutocomplete-endAdornment,
.quick_filter_autocomplete .MuiAutocomplete-endAdornment,
.charging-logs-autocomplete .MuiAutocomplete-endAdornment {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0px, -50%);
    height: auto;
}

.link_styles {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: #0430c0;
    text-decoration: underline;
    cursor: pointer;
}

.table_dark_bg {
    background-color: #f5f6f7;
}

.display_block {
    display: block;
}

.display_inline_block {
    display: inline-block;
}

.comment_wrapper {
    margin-top: 12px;
}

.comment {
    word-break: break-all;
}

.comment_box {
    margin-bottom: 10px;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #f5f6f7;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.selectStations .MuiInputBase-root.MuiOutlinedInput-root {
    background-color: #ffffff;
    border: 1px solid #cfd1d9;
    border-radius: 6px;
    width: 140px;
    height: 36px;
}

.selectStations .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 1px !important;
}

.selectStations .MuiFormControl-root.MuiFormControl-fullWidth {
    height: 36px;
}

.selectStations .MuiInputBase-input {
    border: none;
}

.tile {
    border-radius: 8px;
    background: #f5f6f7;
    padding: 10px;
}

.height_fit_content {
    height: fit-content !important;
}

.font_weight_medium {
    font-weight: 500;
}

.form_error_text {
    color: #d32f2f;
    margin-top: 0 !important;
}

.formik_error {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    color: #d32f2f;
}

.disabled {
    opacity: 0.55 !important;
    cursor: not-allowed !important;
}

.disabled_arrow_icon {
    opacity: 0.25 !important;
    cursor: not-allowed !important;
}

.menu_item {
    padding: 0px;
    padding-right: 10px;
}

.details_item {
    width: 20%;
    color: #70758d;
    padding: 10px 0;
}

.details_value {
    color: #101941;
    font-size: 14px;
    font-weight: 500;
    display: block;
}

.gap_20 {
    gap: 20px;
}

.profile_details {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px 6%;
}

.text_decoration_line_through {
    text-decoration: line-through;
}

.station_visibility_status_col {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 90px;
}

.work_break_keep_all {
    word-break: keep-all;
}

.truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.width_250px {
    width: 250px;
}

.quill {
    background: #f5f6f7 !important;
}

.ql-toolbar.ql-snow,
.ql-container {
    border: none !important;
}

.ql-container {
    height: 69% !important;
    border-top: 1px solid #fff !important;
}

.ql-editor.ql-blank::before {
    font-style: normal !important;
    font-family: "MarkPro";
    color: #b7bac6 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.width_half {
    width: 49% !important;
}

.m_r_6 {
    margin-right: 6px;
}
