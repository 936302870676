.rootContainer {
    padding: 40px;
}

.rootContainer h3 {
    text-align: center;
}

.imgContainer {
    background: var(--light);
    padding: 20px;
    margin-top: 15px;
    border-radius: 8px;
}

.iconContainer {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-size: 500;
}

.imgContainer img {
    height: 30px;
}

.mt40 {
    margin-top: 40px;
}
